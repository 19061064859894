import React from 'react'
import { useHistory } from "react-router-dom";
import AnswersContext from '../contexts/answers';
import Table from '../components/table'
import Container from '../components/container'
import categories from '../components/api/questions.json'
import Interpretation from '../components/interpretation'
import Skeleton from 'react-loading-skeleton';
import style from '../styles/table.module.css'
import Title from '../components/title';
import jsPDF from 'jspdf'
import * as htmlToImage from 'html-to-image';
import CircularProgress from '@material-ui/core/CircularProgress';
import SocialNetworks from '../components/socialButtons';

export default function Results() {

    const [answers] = React.useContext(AnswersContext);
    const [diagnosticAnswers, setDiagnosticAnswers] = React.useState(AnswersContext);
    const [organization, setOrganization] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [indices, setIndices] = React.useState({
        congruencia: [],
        sostenibilidad: [],
        bien_comun: 0
    });
    const Router = useHistory();

    React.useEffect(() => {
        let current_answers = answers;
        /* If the user comes from /diagnostico the I assume there are answers in the context, therefore I store them in localSotrage */
        if (answers && Object.keys(answers).length !== 0) {
            if (typeof window !== "undefined") {
                window.localStorage.setItem("diagnostic_answers", JSON.stringify(answers));
                setOrganization(window.localStorage.getItem("company_name") || "null");
            } else {
                Router.push("/");
            }
        }
        else {
            /* Retrieve the diagnostic answers from localStorage */
            if (typeof window !== "undefined") {
                const diagnostic_answers = window.localStorage.getItem("diagnostic_answers");
                setOrganization(window.localStorage.getItem("company_name") || "null");
                if (diagnostic_answers) {
                    current_answers = JSON.parse(diagnostic_answers);
                } else {
                    Router.push("/");
                }
            } else {
                Router.push("/");
            }
        }
        let indice_sostenibilidad = [];
        let indice_congruencia = [];
        let indice_bien_comun = 0;
        categories.forEach((category) => {
            indice_sostenibilidad.push(category.questions.reduce(function (a, b) { return a + current_answers[b.id] }, 0));
        });
        for (let i = 0; i < categories[0].questions.length; i++) {
            indice_congruencia.push(categories.reduce(function (a, b) { return a + current_answers[b.questions[i].id] }, 0));
        }
        indice_bien_comun += (indice_congruencia.reduce(function (a, b) { return a + b }, 0) + indice_sostenibilidad.reduce(function (a, b) { return a + b }, 0));
        indice_bien_comun = indice_bien_comun / ((categories[0].questions.length * categories[0].questions.length * categories.length) * 2) * 100;
        indice_bien_comun = Math.round(indice_bien_comun);
        setIndices({ congruencia: indice_congruencia, sostenibilidad: indice_sostenibilidad, bien_comun: indice_bien_comun });
        setDiagnosticAnswers(current_answers);
    }, [answers, Router])

    /**
     * Convert the html to a png image and then convert it to a pdf, finally download it
     */
    function saveFile(e) {
        e.preventDefault();
        setLoading(true);
        htmlToImage.toPng(document.getElementById("results"), { quality: 0.95 })
        .then(function (dataUrl) {
            const pdf = new jsPDF('p','mm','a4');
            const imgProps = pdf.getImageProperties(dataUrl);
            const pdfWidth = pdf.internal.pageSize.getWidth(); // Get the final width for the pdf
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width; // Get the final height for the pdf
            let heightLeft = pdfHeight; // To control where to start/finish the last page
            let pageHeight = pdf.internal.pageSize.getHeight(); // Get the heigth of a single page for the pdf
            let position = 0; // The current position of the image to be converted as pdf
            pdf.addImage(dataUrl, 'PNG', 0, position, pdfWidth, pdfHeight, undefined, 'FAST');
            heightLeft -= pageHeight;
            // Add each left page from the image, adjusting the position and the leftHeight.
            while (heightLeft >= -1) {
                position = heightLeft - pdfHeight;
                pdf.addPage();
                pdf.addImage(dataUrl, 'PNG', 0, position, pdfWidth, pdfHeight, undefined, 'FAST');
                heightLeft -= pageHeight;
            }
            pdf.save("Autodiagnóstico Resultados.pdf");
            setLoading(false);
        });
            // setLoading(false);
    }

    if (indices.bien_comun !== 0 && Object.keys(diagnosticAnswers).length !== 0 && organization) {
        return (
            <main id="results">
                <Container>
                    <Title 
						title={"Autodiagnóstico de Sostenibilidad y Congruencia ®️"}
						subtitle={organization !== "null" ? `Resultado de ${organization}` : "Tus resultados"}
					/>
                    <div className={style.tablecomponent}>
                        <Table data={diagnosticAnswers} indices={indices} />

                    </div>
                    <Interpretation indice={indices.bien_comun} />
                    <div className="flex items-center justify-center mt-4">                    
                        {!loading ?
                            <div className="flex items-center justify-center mt-4">
                                <button onClick={saveFile} className="hover:bg-blue-800 text-white bg-b-c py-2 px-6 shadow rounded">Descargar resultados</button>
                            </div>
                            :
                            <div className="mt-2">       
                                <CircularProgress/>
                            </div>
                        }
                    </div>
                    <br></br>
                    <SocialNetworks/>
                    <footer className="mt-6 py-8 border-t-2 border-gray-300 ">
                        <p className="text-justify">
                            * Si quisieras apoyo en la aplicación del autodiagnóstico, en la interpretación de resultados, en la planeación del cambio, en la implementación de acciones de mejora o en la formación de tus colaboradores, escribe a: <span className="text-blue-700 break-words" ><a href="mailto:contacto@diagnosticodesostenibilidad.org ">contacto@diagnosticodesostenibilidad.org</a></span>
                        </p>
                        <br></br>
                        <p className="text-justify">
                            Te invitamos a conocer y a sumarte al <span className="text-blue-700 break-words" ><a href="https://www.compromisosocialmx.mx" target="_blank" rel="noreferrer">www.CompromisoSocialmx.mx</a></span>
                        </p>
                    </footer>
                </Container>
            </main>
        )
    }
    else {
        return (
            <main>
                <Container>
                    <div className="text-center my-12">
                        <h1 className="text-3xl md:text-4xl">Autodiagnóstico de Sostenibilidad y Congruencia ®️</h1>
                    </div>
                    <Skeleton height={50} />
                    <br />
                    <br />
                    <Skeleton count={3} />
                    <footer className="mt-10">
                        <p className="text-xs">
                            * Si quisieras apoyo en la aplicación del autodiagnóstico, en la interpretación de resultados, en la planeación del cambio, en la implementación de acciones de mejora o en la formación de tus colaboradores, escribe a: <span className="text-blue-700" ><a href="mailto:contacto@diagnosticodesostenibilidad.org">contacto@diagnosticodesostenibilidad.org</a></span>
                        </p>
                    </footer>
                </Container>
            </main>
        )
    }
}
