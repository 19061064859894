import React from 'react';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import Title from '../components/title';

import Categories from '../components/categories'
import Container from '../components/container'
import axios from 'axios';

function Diagnostico() {
	const [diagnostic, setDiagnostic] = React.useState({
		token: "",
		questions: []
	});
	const Router = useHistory();

	React.useEffect(() => {
		/**
		 * Entry point for the useEffect hook
		 */
		const validate = async () => {
			/* Check if the user has a token */
			if (typeof window !== "undefined") {
				const local_token = localStorage.getItem("user_token");
				if (local_token) {
					/* If there is an available user token check if also exist answers if not check in api if the user has a diagnostic associated with him otherwise redirect him to create a user */
					const user_answers = localStorage.getItem("diagnostic_answers");
					if (user_answers) {
						Router.push("/resultados");
					} else {
						try {
							const result = await axios.get('/diagnostic/questions', { headers: { user_token: local_token } })
							setDiagnostic({ questions: result.data, token: local_token });
						} catch (error) {
							// console.log(`error`, error);
							Router.push("/");
						}
					}
				} else {
					/* No token in localstorage */
					Router.push("/");
				}
			} else {
				/* window undefined */
				Router.push("/");
			}
		}
		validate();
	}, [Router])

	if (diagnostic.token && diagnostic.questions.length !== 0) {
		return (
			<>
				<Container>
					<Title 
						title={"Autodiagnóstico de Sostenibilidad y Congruencia ®️"}
						subtitle="Primer paso de las empresas hacia la responsabilidad social empresarial"
					/>
					<div className="-mx-8 -my-6 md:-my-0 sm:mx-5 md:mx-10 lg:mx-16">
						<Categories categories={diagnostic.questions} token={diagnostic.token} />
					</div>
				</Container>
			</>
		);
	} else {
		return (
			<Container>
				<div className="flex my-12">
					<div className="mx-auto">
						<CircularProgress size={100} />
					</div>
				</div>
			</Container>
		)
	}

}

export default Diagnostico;
