import { Link } from "react-router-dom";

function Footer() {
	return (
		// <footer className="container mx-auto fixed bottom-0 right-0 left-0 flex justify-center items-center h-24">
		<footer className="border-t-2 border-gray-300 mx-auto flex justify-center items-center py-5 sm:py-8 text-xs md:text-sm">
		  <p>Puedes consultar el</p>
		  <Link to="/aviso-de-privacidad" className="text-blue-700 ml-1">Aviso de privacidad</Link>
		</footer>
	)
}

export default Footer
