import Question from './question';
import React from 'react';

function Questions({ questions }) {

	if (questions) {
		return (
			<div className="w-full mb-2">
				{questions.map((question, index) => {
					return (
						<div key={index} className="mb-4">
							<h4>{index + 1}. {question.question}</h4>

							<Question question={question} />
						</div>
					)
				})}
			</div>
		)
	} else {
		return null
	}
}

export default Questions
