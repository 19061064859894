import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { CircularProgress, FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import axios from 'axios';
import states from '../../components/api/mx-states.json';
import cities from '../../components/api/mx-cities.json';
import organizations from '../../components/api/organizations.json';
import '../../styles/global.css';

function Form() {
    const [loading, setLoading] = useState(false);
    const Router = useHistory();

    const [error, setError] = useState("");
    // const [organizationBelongsTo, setOrganizationBelongsTo] = useState("");
    const [organizationBelongsTo, setOrganizationBelongsTo] = useState([]);
    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        confirm_email: "",
        job: "",
        organization: "",
        company: "",
        city: "",
        state: ""
    });

    const registerUser = async (event) => {
        event.preventDefault()
        setError("");
        setLoading(true);
        if (user.first_name && user.last_name && user.last_name) {
            if (validateName(user.first_name)) {
                if (validateName(user.last_name)) {
                    if (validateEmail(user.email) && user.email === user.confirm_email) {
                        if (validatePhoneNumber(user.phone)) {
                            // Everything is correctly
                            try {
                                // Check if the user checked the OTRO element in the organization which belongs to
                                if (organizationBelongsTo.includes("OTRO")) {
                                    if (!user['organization']) {
                                        setLoading(false);
                                        setError("Debes de incluir la el organismo al que pertenece");
                                        return;
                                    }
                                }
                                // Find and replace the "OTRO" element of the array for the input field typed from the user
                                let orgs = [...organizationBelongsTo];
                                let index = orgs.indexOf("OTRO");

                                if (index !== -1) {
                                    orgs[index] = user['organization'];
                                }
                                // Change the array to string separated by commas
                                const user_data = {
                                    ...user,
                                    'organization': orgs.toString()
                                }
                                const result = await axios.post('/user', user_data)
                                if (result.data.token) {
                                    if (typeof window !== "undefined") {
                                        localStorage.setItem("user_token", result.data.token);
                                        localStorage.setItem("company_name", user.company);
                                    }
                                    Router.push("/diagnostico");
                                } else {
                                    setLoading(false);
                                    setError("Ocurrió un error inesperado, intenta más tarde");
                                }
                            } catch (error) {
                                console.log(`error`, error)
                                setLoading(false);
                                setError("Ocurrió un error inesperado, intenta más tarde");
                            }
                        } else {
                            setLoading(false);
                            setError("Número telefónico mal formado (debe tener solo 10 dígitos)");
                        }
                    } else {
                        setLoading(false);
                        setError("Correo mal formado o no coincide");
                    }
                } else {
                    setLoading(false);
                    setError("Al parecer tu apellido es incorrecto (asegurate de que no tenga espacios)");
                }
            } else {
                setLoading(false);
                setError("Al parecer tu nombre es incorrecto (asegurate de que no tenga espacios)");
            }
        } else {
            setLoading(false);
            setError("Debes ingresar los datos solicitados");
        }
    }
    const validatePhoneNumber = (phone) => {
        const re = /^\d{10}$/;
        return re.test(String(phone));
    }
    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    function validateName(name) {
        const reg = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;
        return reg.test(name);
    }

    /**
     * Handle the user change from his inputs in the form
     * @param {object} e event object from the checkbox element
     */
    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.id === "state") {
            setUser({ ...user, [e.target.id]: e.target.value, "city": "" });
        } else {
            setUser({ ...user, [e.target.id]: e.target.value });
        }
        // console.log(`object`, {[e.target.id]: e.target.value })
    }

    /**
     * Handle the selection of the organization from the user
     * @param {object} e event object from the checkbox element
     */
    const handleChangeOrganization = (e) => {
        let orgs = [...organizationBelongsTo];
        if (e.target.checked) {
            // If the element is checked
            orgs.push(e.target.name);
        } else {
            // Deselected
            orgs = orgs.filter((a) => a !== e.target.name);
        }
        setOrganizationBelongsTo(orgs);
    }

    return (
        <div>
            <h3 className="text-xl text-center mt-10">Registro</h3>
            <div className="flex items-center justify-center mb-10">
                <form className="w-full max-w-xl" onSubmit={registerUser} noValidate autoComplete="on">
                    {/* name */}
                    <h4 className="text-lg mb-4">Datos de contacto</h4>
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                Nombre*
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="first_name" autoComplete="given_name" type="text" placeholder="Nombre" onChange={handleChange} />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                Apellidos*
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="last_name" autoComplete="family_name" type="text" placeholder="Apellidos" onChange={handleChange} />
                        </div>
                    </div>
                    {/* phone */}
                    <div className="flex flex-wrap ">
                        <div className="w-full">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-phone">
                                Número telefónico*
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="phone" type="tel" autoComplete="tel" placeholder="10 dígitos" onChange={handleChange} />
                        </div>
                    </div>
                    {/* email */}
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3 md:w-1/2 ">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-email">
                                Correo*
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="email" autoComplete="email" placeholder="correo@ejemplo.com" onChange={handleChange} />
                        </div>
                        <div className="w-full px-3 md:w-1/2">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-confirm_email">
                                Confirmar Correo*
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="confirm_email" type="email" autoComplete="email" placeholder="correo@ejemplo.com" onChange={handleChange} />
                        </div>
                    </div>
                    {/* company */}
                    <h4 className="text-lg mb-4">Datos de la Empresa / Institución</h4>
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full px-3 md:w-1/2">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-company">
                                Nombre de la Empresa / Institución*
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="company" type="text" autoComplete="off" placeholder="Empresa / Institución" onChange={handleChange} />
                        </div>
                        <div className="w-full px-3 md:w-1/2 ">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-job">
                                Cargo en la Empresa / Institución
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="job" type="text" autoComplete="off" placeholder="Cargo" onChange={handleChange} />
                        </div>
                        <div className="w-full px-3 mb-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 truncate" htmlFor="grid-organization">
                                Organismo Empresarial al que pertenece
                            </label>
                            <FormControl component="fieldset" >
                                <FormGroup>
                                    {organizations.map((organization, i) => {
                                        return (
                                            <FormControlLabel
                                                key={i}
                                                control={<Checkbox onChange={handleChangeOrganization} name={organization.value} />}
                                                label={organization.label}
                                            />
                                        )
                                    })}
                                </FormGroup>
                            </FormControl>
                        </div>
                        {
                            organizationBelongsTo.includes("OTRO") && <div className="w-full px-3 mb-3">
                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="organization" type="text" autoComplete="off" placeholder="Otro Organismo" onChange={handleChange} />
                            </div>
                        }
                    </div>
                    {/* location */}
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full px-3 md:w-1/2">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                                Estado*
                            </label>
                            <select id="state" onChange={handleChange} className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer ${!user.state && "text-gray-200 text-opacity-60"} `}>
                                <option value="" disabled selected>Estado</option>
                                {states.map((state, i) => {
                                    return (
                                        <option key={i} value={state.id}>{state.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {
                            user.state ? 
                                <div className="w-full px-3 md:w-1/2 ">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                                        Ciudad*
                                    </label>
                                    <select id="city" onChange={handleChange} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                                        <option value="" disabled selected>Ciudad</option>
                                        {cities[user.state].map((city, i) => {
                                            return (
                                                <option key={i} value={city}>{city}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            :
                            <div className="w-full px-3 md:w-1/2 ">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                                    Ciudad*
                                </label>
                                <select id="city" disabled className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                                    {["Ciudad"].map((city, i) => {
                                        return (
                                            <option key={i} value={city}>{city}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        }
                    </div>
                    {/* requirements */}
                    <div className="flex flex-row-reverse mb-6">
                        <div className="">
                            <p className="text-gray-600 text-xs italic">*Campos Requeridos</p>
                        </div>
                    </div>
                    {/* buttons */}
                    {loading ?
                        <div className="flex justify-center">
                            <CircularProgress size={30} />
                        </div>
                        :
                        <div className="flex">
                            {(user.email && user.first_name && user.last_name && user.phone && user.last_name && user.confirm_email && user.city && user.state && user.company) ?
                                <button className="mx-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:shadow-outline focus:outline-none " type="submit">Continuar</button>
                                :
                                // <button className="btn mx-auto " disabled>Create Account</button>
                                <button className="mx-auto bg-gray-400 cursor-default text-white font-bold py-2 px-4 rounded focus:outline-none" type="button">Continuar</button>
                            }
                        </div>
                    }
                    {/* error  */}
                    <div className="flex pt-2">
                        {error && <p className="text-red-500 text-xs italic mx-auto">{error}</p>}
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Form