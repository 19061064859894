import React from 'react';
import Container from '../components/container'
import Form from '../components/form'
import { useHistory } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import Footer from '../components/footer';
import Title from '../components/title';
import SocialNetworks from '../components/socialButtons';

export default function IndexPage() {
	const [userAnswers, setUserAnswers] = React.useState(-1);
	const Router = useHistory();

	React.useEffect(() => {
		if (typeof window !== "undefined") {
			const answers = localStorage.getItem("diagnostic_answers");
			if (answers) {
				/* If there are already answers set to 1 */
				setUserAnswers(1);
			} else {
				const user_token = localStorage.getItem("user_token");
				if (user_token) {
					/* If there is a user token is meant that the user has already created his account, but decided to create another diagnostic. Set to 2 */
					setUserAnswers(2);
				} else {
					/* If is a new user */
					setUserAnswers(0);
				}
			}
		}
	}, [])

	/* Send the user to the results page */
	const goToResults = (e) => {
		e.preventDefault();
		Router.push("/resultados");
	}

	/* New diagnostic, clear the answers from localstorage */
	const newDiagnostic = (e) => {
		e.preventDefault();
		if (typeof window !== "undefined") {
			localStorage.removeItem("diagnostic_answers");
			Router.push("/diagnostico");
		}
	}

	return (
		<div>
			<Container>
				<Title 
					title={"Autodiagnóstico de Sostenibilidad y Congruencia ®️"}
					subtitle="Primer paso de las empresas hacia la responsabilidad social empresarial"
				/>
				<main style={{ minHeight: "65vh" }}>

					<p className="text-xl text-justify">
						La vocación del empresario es una noble vocación cuando se deja interpelar por un sentido trascendente de la vida, cuando más allá de agregar valor y generar utilidades, busca desarrollar íntegramente a los hombres y mujeres que conforman su empresa y con los que se relaciona.
					</p>
					<br />
					<p className="text-xl text-justify">
						Esta forma de Gestión Empresarial Centrada en la Persona©, además de ser responsable y contribuir al bien común, favorece relaciones sostenibles con los grupos de interés, confirmando la rentabilidad de los valores.
					</p>
					<br />
					<p className="text-xl text-justify">
						Esta herramienta te ayudará a identificar las áreas de oportunidad para incrementar la contribución de tu empresa al desarrollo integral de las personas en tu organización y en su entorno.
					</p>

					{/* If it is loading (-1) */}
					{userAnswers === -1 ?
						<Skeleton height={50} />
						:
						<>
							{/* If the user is new and has not answers yet (0) */}
							{userAnswers === 0 ?
								<Form />
								:
								<>
									{/* If there are answers already in localStore (1) */}
									{userAnswers === 1 ?
										<>
											<div className="flex justify-center mt-10">
												<button className="hover:bg-blue-800 text-white bg-b-c py-2 px-6 shadow rounded" onClick={goToResults}>Ver Resultados</button>
											</div>

											{/* Give the user the option to create a new diagnostic */}
											<div className="flex justify-center my-10">
												<button className="hover:bg-gray-300 text-black border py-2 px-6 shadow rounded" onClick={newDiagnostic}>Volver a llenar Autodiagnóstico de Sostenibilidad y Congruencia ®️</button>
											</div>
										</>
										:
										<>
											{/* If the user has an account but decided to create a new diagnostic */}
											<div className="flex justify-center my-10">
												<button className="hover:bg-gray-300 text-black border py-2 px-6 shadow rounded" onClick={e => { e.preventDefault(); Router.push("/diagnostico"); }}>Volver a llenar Autodiagnóstico de Sostenibilidad y Congruencia ®️</button>
											</div>
										</>
									}
								</>
							}
						</>
					}
				</main>
				<SocialNetworks/>
				<Footer style={{ minHeight: "15vh" }} />
			</Container>
		</div>
	)

}
