import React from 'react'
import categories from '../api/questions.json'
import style from '../../styles/table.module.css'
export default function Table({ data, indices }) {
    if (data && categories && indices) {
        return (
            <div className="w-full my-4">
                <table className="w-full">
                    <thead>
                        <tr>
                            <th style={{ width: "3%" }}></th>
                            <th style={{ width: "16%" }} className={`${style["td-category"]}`}></th>
                            <th style={{ width: "48%" }} colSpan={3} className="border border-black uppercase">Dimensiones</th>
                            {/* vertical division */}
                            <th style={{ width: "2%" }}></th>
                            {/* vertical division */}
                            <th style={{ width: "16%" }}></th>
                            <th style={{ width: "16%" }}></th>
                        </tr>
                        <tr>
                            <th style={{ width: "3%" }}></th>
                            <th style={{ width: "16%" }}></th>
                            <th style={{ width: "16%" }} className="border border-black">Generación y distribución de riqueza</th>
                            <th style={{ width: "16%" }} className="border border-black"> Ética y transparencia</th>
                            <th style={{ width: "16%" }} className="border border-black">Calidad de vida</th>
                            {/* vertical division */}
                            <th style={{ width: "2%" }}></th>
                            {/* vertical division */}
                            <th style={{ width: "16%" }} className={`border border-black font-semibold`}>Índice de sostenibilidad (Valor Máximo: 9)</th>
                        </tr>
                    </thead>
                    <tbody className="break-words">
                        {/* Top */}
                        {categories.map((category, index) => {
                            return (
                                <tr key={index}>
                                    {/* left */}
                                    {index === 0 && <td rowSpan={categories.length} className={`${style.rotate} border border-black uppercase`}><p>Grupos de interés</p></td>}
                                    <td className={`border border-black md:pl-2 ${style["td-category"]}`}>
                                        {category.category}
                                    </td>
                                    {
                                        category.questions.map((question, j) => {
                                            return (
                                                <td key={j} className={`${data[question.id] === 3 ? style.cell_bg_green : data[question.id] === 2 ? style.cell_bg_yellow : style.cell_bg_red} mx-auto border border-black text-center`}>
                                                    {data[question.id]}
                                                </td>
                                            )
                                        })
                                    }
                                    {/* vertical division */}
                                    <td></td>
                                    {/* vertical division */}

                                    {/* right */}
                                    <td className={`${indices.sostenibilidad[index] >= 8 ? style.cell_bg_green : (indices.sostenibilidad[index] >= 5 && indices.sostenibilidad[index] <= 7) ? style.cell_bg_yellow : style.cell_bg_red} mx-auto border border-black text-center`}>
                                        {indices.sostenibilidad[index]}
                                    </td>
                                </tr>
                            )
                        })}
                        {/* End Top */}
                        <tr style={{ height: '1.5em' }}></tr>
                        {/* Bottom */}
                        <tr>
                            {/* left */}
                            <td className={`${style.rotate}`}></td>
                            <td className={`border border-black text-center font-semibold`}>Índice de congruencia (Valor Máximo: 24)</td>
                            {indices.congruencia.map((indice, i) => {
                                return (
                                    <td key={i} className={`${indice >= 20 ? style.cell_bg_green : (indice >= 14 && indice <= 19) ? style.cell_bg_yellow : style.cell_bg_red} mx-auto border border-black text-center`}>
                                        {indice}
                                    </td>
                                )
                            })}
                            {/* vertical division */}
                            <td></td>
                            {/* vertical division */}

                            {/* Right */}
                            <td className={`${indices.bien_comun >= 80 ? style.cell_bg_green : indices.bien_comun >= 60 && indices.bien_comun <= 79 ? style.cell_bg_yellow : style.cell_bg_red} mx-auto border border-black text-center`}>
                                {indices.bien_comun}
                            </td>
                            <td className={`border border-black text-center font-semibold`}>Índice de bien común (Valor Máximo: 100)</td>
                        </tr>
                        {/* End Bottom */}
                    </tbody>
                </table>
            </div>
        )
    } else {
        return null
    }
}
