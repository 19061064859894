import style from '../../styles/table.module.css'
const my = "4";

export default function Interpretation({ indice }) {
    if (indice === 0 || indice) {
        return (
            <div>
                <div className="mt-10">
                    <p className={`my-${my}`}>Las preguntas del autodiagnóstico hacen referencia al tipo de relación que lleva la empresa con sus grupos de interés, en cada una de las tres dimensiones o ámbitos de toda persona, según Aristóteles: TENER, SER y TRASCENDER (Ver Documento <span className="text-blue-700" ><a href="https://bit.ly/3dEqt28" target="_blank" rel="noreferrer">“La Rentabilidad de los Valores”</a></span>):</p>
                    <ul className="ml-5 md:ml-6 list-disc">
                        <li>TENER		Necesidades materiales: 	Generación y Distribución de la Riqueza.</li>
                        <li>SER		Necesidades humanas: 		Calidad de Vida.</li>
                        <li>TRASCENDER	Necesidades trascendentes: 	Ética y Transparencia.</li>
                    </ul>
                    <p className={`my-${my}`}>Dependiendo de qué tanto contribuye la empresa al bien de cada grupo de interés en cada dimensión, el valor de la respuesta será 1, 2 ó 3.</p>
                    <p className={`my-${my}`}>Así, con base en tus respuestas al autodiagnóstico, obtienes en esta matriz una vista rápida, completa, estructurada y precisa; una mejor comprensión de la realidad de tu empresa a partir de los Índices de Sostenibilidad, Congruencia y Bien Común, y la identificación de 24 áreas en las que podrás desarrollar tu gestión empresarial más centrado en atender de forma integral las necesidades de las personas en tu ámbito de influencia.</p>
                    <h3 className={`mt-8 mb-${my} uppercase font-semibold tracking-wide `}>INTERPRETACIÓN Y ANÁLISIS DE RESULTADOS</h3>
                    <p className={`my-${my}`}>Para comenzar tu análisis, te sugerimos observar el índice de bien común, el cual, de una manera agregada, concentra los resultados de toda la matriz; posteriormente puedes analizar horizontal y verticalmente los Índices de Sostenibilidad y de Congruencia, para finalmente revisar cada una de las celdas interiores de la matriz. </p>
                    <h4 className={`my-${my} font-semibold tracking-wide`}>Índice de Sostenibilidad</h4>
                    <p className={`my-${my}`}>Indica la capacidad de la empresa para entregar valor de manera continua a los grupos de interés con los que se relaciona. La permanencia de la empresa en el largo plazo puede depender de cuán satisfactoria sea la respuesta a las expectativas de dichos grupos relacionados.</p>
                    <p className={`my-${my}`}>El valor máximo de este índice es de 24 puntos para cada Dimensión y el mínimo 8 puntos.</p>
                    <div className={`flex flex-auto`}>
                        <div className="mx-auto">
                            <table className={`${style.indices_table}`}>
                                <thead>
                                    <tr className="h-11">
                                        <th colSpan={3} className={`${style.cell_bg_gray} border border-black text-center`}>Índice de Sostenibilidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="h-12">
                                        <td className={`${style.cell_bg_red} border border-black text-center`}>3 a 4</td>
                                        <td className={`${style.cell_bg_yellow} border border-black text-center`}>5 a 7</td>
                                        <td className={`${style.cell_bg_green} border border-black text-center`}>8 a 9</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <h4 className={`my-${my} font-semibold tracking-wide`}>Índice de Congruencia</h4>
                    <p className={`my-${my}`}>Indica el grado de madurez de la Gestión Empresarial centrada en la Persona de la empresa medida como la respuesta a las expectativas de las personas que conforman los grupos de interés para satisfacer sus necesidades materiales, humanas y trascendentes.</p>
                    <p className={`my-${my}`}>El valor máximo de este índice es de 24 puntos para cada Dimensión y el mínimo 8 puntos.</p>
                    <div className="flex flex-auto">
                        <div className="mx-auto">
                            <table className={`${style.indices_table}`}>
                                <thead>
                                    <tr className="h-11">
                                        <th colSpan={3} className={`${style.cell_bg_gray} border border-black text-center`}>Índice de Congruencia</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="h-12">
                                        <td className={`${style.cell_bg_red} border border-black text-center`}>8 a 13</td>
                                        <td className={`${style.cell_bg_yellow} border border-black text-center`}>14 a 19</td>
                                        <td className={`${style.cell_bg_green} border border-black text-center`}>20 a 24</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <h4 className={`my-${my} font-semibold tracking-wide`}>Índice de Bien Común</h4>
                    <p className={`my-${my}`}>Refleja el grado de contribución de la empresa al bien común. Suma los valores en las filas del índice de sostenibilidad y en las columnas del Índice de Congruencia, y el resultado es normalizado a una escala de 100 puntos.</p>
                    <div className="flex flex-auto">
                        <div className="mx-auto">
                            <table className={`${style.indices_table}`}>
                                <thead>
                                    <tr className="h-11">
                                        <th colSpan={3} className={`${style.cell_bg_gray} border border-black text-center`}>Índice de Bien Común</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="h-12">
                                        <td className={`${style.cell_bg_red} border border-black text-center`}>33 a 59</td>
                                        <td className={`${style.cell_bg_yellow} border border-black text-center`}>60 a 79</td>
                                        <td className={`${style.cell_bg_green} border border-black text-center`}>80 a 100</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    {indice >= 80 ?
                        <div>
                            <h2>Felicidades</h2>
                            <p>Un índice de bien común verde es reflejo de una gestión centrada en la persona. A mayor el indicador, mayor la madurez, la sostenibilidad y la congruencia.</p>
                            <p>Para mantenerlo y/o mejorarlo, asegúrate de alinear la cultura de la empresa, profundizando la formación de tus colaboradores en todos los niveles de la organización, haciéndoles partícipes e incentivando la generación de valor amplio mediante los propios procesos de negocio.</p>
                            <p>Te proponemos volver sobre las preguntas del autodiagnóstico, y reflexionar con tu equipo directivo, particularmente sobre aquéllas que resultaron en rojo o amarillo, y elabora un plan de trabajo de forma participativa*. La tarea es permanente y siempre encontrarás áreas de oportunidad.</p>
                            <p>Te sugerimos aplicar el autodiagnóstico periódicamente, recomendamos al menos una vez al año, para evaluar el impacto de tus acciones de mejora y te invitamos a compartir tus buenas prácticas de Gestión Empresarial Centrada en la Persona© en el siguiente sitio: <span className="text-blue-700" ><a href="https://bit.ly/3spE2Z1" target="_blank" rel="noreferrer">https://bit.ly/3spE2Z1</a></span></p>
                        </div>
                        :
                        <>
                            {(indice >= 60 && indice <= 79) ?
                                <div>
                                    <p>Un índice de bien común amarillo deja ver que estás trabajando por ser más responsable socialmente. Probablemente tu empresa está en fase de implementación de buenas prácticas de gestión centrada en la persona, en función de prioridades ya identificadas.</p>
                                    <p>Asegura que tu equipo directivo comparte tus principios y amplía el alcance de la formación a mandos medios. Vuelve con ellos sobre las preguntas del autodiagnóstico, y elaboren conjuntamente los planes de trabajo para incrementar valor generado a los grupos de interés*.</p>
                                    <p>Te sugerimos responder el autodiagnóstico periódicamente, recomendamos al menos una vez al año, para evaluar el impacto de tus acciones de mejora. </p>
                                </div>
                                :
                                <div>
                                    <p>Con un índice de bien común rojo, hay mucho por hacer y conviene que empieces pronto, pues está en riesgo la sostenibilidad de la relación con uno o más grupos de interés. La propia matriz de resultados te dará una pauta para determinar prioridades. Empieza por despertar consciencia en tu equipo directivo para alinearlos a tus objetivos y elaborar juntos un plan de trabajo. Puedes iniciar con pequeñas acciones para que, viendo resultados, se motiven a realizar cambios mayores; tu liderazgo es fundamental para impulsarlos*.</p>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        )
    }
    else {
        return null
    }
}