import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import AnswersContext from '../../../../contexts/answers';

function Question({ question }) {
    const [value, setValue] = React.useState("");
    const [answers, setAnswers] = React.useContext(AnswersContext);

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setAnswers({ ...answers, [question.id]: parseInt(event.target.value) });
        // console.log(`answers`, {...answers, [question.id]: parseInt(event.target.value) });
    };
    return (
        <>
            <FormControl component="fieldset">
                <RadioGroup row aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>
                    {question.options.map((option, index) => {
                        return (
                            <FormControlLabel className="w-full sm:w-auto" key={index} value={(option.value).toString()} control={<Radio checked={answers[question.id] === option.value} disableRipple style={{ color: "rgba(23, 161, 80, 1)" }} />} label={<p>{option.name}</p>} />
                        )
                    })}
                </RadioGroup>
            </FormControl>
        </>
    )
}

export default Question;
