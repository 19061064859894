import React from 'react';
import Home from './views/index';
import Diagnostic from './views/diagnostic';
import Results from './views/results';
import PageNotFound from './views/404';
import Privacy from './views/privacy';
import axios from 'axios';
import { BrowserRouter, Route, Switch } from "react-router-dom";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {
	config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
	return config;
});

function App() {
	return (
		<div id="app" >
			<BrowserRouter>
				<div>
					<Switch >
						<Route exact path="/" component={Home} />
						<Route exact path="/diagnostico" component={Diagnostic} />
						<Route exact path="/resultados" component={Results} />
						<Route exact path="/aviso-de-privacidad" component={Privacy} />
						<Route component={PageNotFound}></Route>
					</Switch>
				</div>
			</BrowserRouter>
		</div>
	);
}


export default App;
