import logo1 from '../../assets/logo1.png';
import logo2 from '../../assets/logo2.png';

function Title({title, subtitle}) {
    return (
        <>
            <div className="flex justify-center flex-col items-center sm:flex-row sm:justify-between mt-6">
                <div className="">
                    <img src={logo1} alt="Uniapac" className="w-96 h-auto sm:pr-2" />
                </div>
                <div className="">
                    <img src={logo2} alt="Uniapac" className="w-96 h-auto sm:pl-2" />
                </div>
            </div>
            <div className="text-center mt-6 mb-12 flex flex-col items-center bg-b-g shadow rounded px-1 md:px-0">
                <h1 className="text-3xl md:text-4xl txt-title">
                    {title}
                </h1>
                <h2 className="text-xl md:text-2xl mb-6 text-white">
                    {subtitle}
                </h2>
            </div>
        </>
    );
}

export default Title;
