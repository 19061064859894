import React from 'react';

const PageNotFound = () => {
    return (
        // <div className='flex h-screen justify-center items-center'>
        <div className='h-screen flex flex-col justify-center items-center'>
            <div className='from-purple-600 text-2xl max-w-lg'>
                <h1>404 | Oops! Parece que esta p&aacute;gina no existe, o esta temporalmente sin servicio </h1>
            </div>
            <div className='from-purple-600 text-2xl max-w-md'>
                <br />
                <a className="py-2 px-4 rounded shadow bg-indigo-300 hover:bg-indigo-400" href="/">Regresar</a>
            </div>
        </div>
    )
}
export default PageNotFound;