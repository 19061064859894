import React from 'react';
import '../../styles/socialnetworkbuttons.css';

function socialNetworks() {
    return (
        /* Got them from: https://sharingbuttons.io/ */
        <div className="flex flex-wrap justify-center">
            {/* <!-- Sharingbutton Facebook --> */}
            <a className="resp-sharing-button__link" href="https://facebook.com/sharer/sharer.php?u=http%3A%2F%2Fhatch.mx%2Fpublic%2Fclientes%2Fautodiagnostico" rel="noreferrer" target="_blank"  aria-label="Facebook">
            <div className="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--medium"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--circle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><circle cx="12" cy="12" r="11.5"/><path d="M15.84 9.5H13.5V8.48c0-.53.35-.65.6-.65h1.4v-2.3h-2.35c-2.3 0-2.65 1.7-2.65 2.8V9.5h-2v2h2v7h3v-7h2.1l.24-2z"/></svg></div>Facebook</div>
            </a>

            {/* <!-- Sharingbutton Twitter --> */}
            <a className="resp-sharing-button__link" href="https://twitter.com/intent/tweet/?text=%C2%BFYa%20llenaste%20el%20Autodiagn%C3%B3stico%20de%20Sostenibilidad%20y%20Congruencia%3F%20Conoce%20el%20%C3%ADndice%20de%20bien%20com%C3%BAn%20de%20tu%20empresa.&amp;url=http%3A%2F%2Fhatch.mx%2Fpublic%2Fclientes%2Fautodiagnostico" rel="noreferrer" target="_blank"  aria-label="Twitter">
            <div className="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--medium"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--circle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.5 7.4l-2 .2c-.4-.5-1-.8-2-.8C13.3 6.8 12 8 12 9.4v.6c-2 0-4-1-5.4-2.7-.2.4-.3.8-.3 1.3 0 1 .4 1.7 1.2 2.2-.5 0-1 0-1.2-.3 0 1.3 1 2.3 2 2.6-.3.4-.7.4-1 0 .2 1.4 1.2 2 2.3 2-1 1-2.5 1.4-4 1 1.3 1 2.7 1.4 4.2 1.4 4.8 0 7.5-4 7.5-7.5v-.4c.5-.4.8-1.5 1.2-2z"/><circle cx="12" cy="12" r="11.5"/></svg></div>Twitter</div>
            </a>

            {/* <!-- Sharingbutton LinkedIn --> */}
            <a className="resp-sharing-button__link" href="https://www.linkedin.com/shareArticle?mini=true&amp;url=http%3A%2F%2Fhatch.mx%2Fpublic%2Fclientes%2Fautodiagnostico&amp;title=%C2%BFYa%20llenaste%20el%20Autodiagn%C3%B3stico%20de%20Sostenibilidad%20y%20Congruencia%3F&amp;summary=%C2%BFYa%20llenaste%20el%20Autodiagn%C3%B3stico%20de%20Sostenibilidad%20y%20Congruencia%3F%20Conoce%20el%20%C3%ADndice%20de%20bien%20com%C3%BAn%20de%20tu%20empresa.&amp;source=http%3A%2F%2Fhatch.mx%2Fpublic%2Fclientes%2Fautodiagnostico" rel="noreferrer" target="_blank"  aria-label="LinkedIn">
            <div className="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--medium"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--circle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><circle cx="12" cy="12" r="11.5"/><path d="M15 12.5c-.28 0-.5.22-.5.5v3.5h-3s.03-6.48 0-7h3v.83s.46-.75 1.7-.75c1.56 0 2.3 1.12 2.3 3.3v3.62h-3V13c0-.28-.23-.5-.5-.5zm-7.5-3h2v7h-2z"/><circle cx="8.5" cy="6.5" r="1"/></svg></div>LinkedIn</div>
            </a>

            {/* <!-- Sharingbutton E-Mail --> */}
            <a className="resp-sharing-button__link" href="mailto:?subject=%C2%BFYa%20llenaste%20el%20Autodiagn%C3%B3stico%20de%20Sostenibilidad%20y%20Congruencia%3F&amp;body=%C2%BFQu%C3%A9%20tan%20bien%20est%C3%A1%20el%20%C3%ADndice%20de%20bien%20com%C3%BAn%20de%20tu%20empresa%3F%20Haz%20un%20autodiagn%C3%B3stico%20y%20desc%C3%BAbrelo%3A%20http%3A%2F%2Fhatch.mx%2Fpublic%2Fclientes%2Fautodiagnostico" rel="noreferrer" target="_self"  aria-label="E-Mail">
            <div className="resp-sharing-button resp-sharing-button--email resp-sharing-button--medium"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--circle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.5 16c0 .8-.7 1.5-1.5 1.5H6c-.8 0-1.5-.7-1.5-1.5V8c0-.8.7-1.5 1.5-1.5h12c.8 0 1.5.7 1.5 1.5v8zm-2-7.5L12 13 6.5 8.5m11 6l-4-2.5m-7 2.5l4-2.5"/><circle cx="12" cy="12" r="11.5"/></svg></div>E-Mail</div>
            </a>

            {/* <!-- Sharingbutton Tumblr --> */}
            {/* <a className="resp-sharing-button__link" href="https://www.tumblr.com/widgets/share/tool?posttype=link&amp;title=%C2%BFYa%20llenaste%20el%20Autodiagn%C3%B3stico%20de%20Sostenibilidad%20y%20Congruencia%3F&amp;caption=%C2%BFYa%20llenaste%20el%20Autodiagn%C3%B3stico%20de%20Sostenibilidad%20y%20Congruencia%3F&amp;content=http%3A%2F%2Fhatch.mx%2Fpublic%2Fclientes%2Fautodiagnostico&amp;canonicalUrl=http%3A%2F%2Fhatch.mx%2Fpublic%2Fclientes%2Fautodiagnostico&amp;shareSource=tumblr_share_button" rel="noreferrer" target="_blank"  aria-label="Tumblr">
            <div className="resp-sharing-button resp-sharing-button--tumblr resp-sharing-button--medium"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--circle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><circle cx="12" cy="12" r="11.5"/><path d="M12.5 4.5v3h2v2h-2v3.72c0 2.47 1.48 2.7 3 1.7v2.7c-4.1 1.92-6-.62-6-3.6V9.5h-2V8.14c.55-.18 1.24-.43 1.63-.77.4-.33.7-.73.94-1.2.24-.46.4-.95.5-1.67h1.93z"/></svg></div>Tumblr</div>
            </a> */}

            {/* <!-- Sharingbutton Pinterest --> */}
            {/* <a className="resp-sharing-button__link" href="https://pinterest.com/pin/create/button/?url=http%3A%2F%2Fhatch.mx%2Fpublic%2Fclientes%2Fautodiagnostico&amp;media=http%3A%2F%2Fhatch.mx%2Fpublic%2Fclientes%2Fautodiagnostico&amp;description=%C2%BFYa%20llenaste%20el%20Autodiagn%C3%B3stico%20de%20Sostenibilidad%20y%20Congruencia%3F" rel="noreferrer" target="_blank"  aria-label="Pinterest">
            <div className="resp-sharing-button resp-sharing-button--pinterest resp-sharing-button--medium"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--circle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><circle cx="12" cy="12" r="11.5"/><path d="M8 11.2c-.15-.32-.25-.72-.25-1.22 0-2.32 1.74-4.4 4.53-4.4 2.47 0 3.82 1.5 3.82 3.52 0 2.64-1.17 4.88-2.9 4.88-.97 0-1.7-.8-1.46-1.77.28-1.14.8-2.4.8-3.23 0-.76-.4-1.38-1.23-1.38-.95 0-1.74 1-1.74 2.37 0 .86.3 1.45.3 1.45l-1.2 5c-.34 1.5-.04 3.33-.02 3.5.02.1.16.15.22.06.1-.12 1.26-1.56 1.66-3l.66-2.53c.32.6 1.25 1.14 2.24 1.14 2.95 0 4.95-2.7 4.95-6.3 0-2.73-2.3-5.27-5.82-5.27-4.36 0-6.57 3.14-6.57 5.75 0 .85.18 1.64.53 2.28l1.5-.8z"/></svg></div>Pinterest</div>
            </a> */}

            {/* <!-- Sharingbutton WhatsApp --> */}
            {/* <a className="resp-sharing-button__link" href="whatsapp://send?text=%C2%BFYa%20llenaste%20el%20Autodiagn%C3%B3stico%20de%20Sostenibilidad%20y%20Congruencia%3F%20http%3A%2F%2Fhatch.mx%2Fpublic%2Fclientes%2Fautodiagnostico" rel="noreferrer" target="_blank"  aria-label="WhatsApp">
            <div className="resp-sharing-button resp-sharing-button--whatsapp resp-sharing-button--medium"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--circle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="11.5"/><path stroke-width="1px" d="M17.6 6.2c-1.5-1.5-3.4-2.3-5.5-2.3-4.3 0-7.8 3.5-7.8 7.8 0 1.4.4 2.7 1 3.9l-1.1 4 4.1-1.1c1.1.6 2.4.9 3.7.9 4.3 0 7.8-3.5 7.8-7.8.1-2-.7-3.9-2.2-5.4zm-5.5 11.9c-1.2 0-2.3-.3-3.3-.9l-.2-.1-2.4.6.7-2.4-.2-.2c-.6-1-1-2.2-1-3.4 0-3.6 2.9-6.5 6.5-6.5 1.7 0 3.3.7 4.6 1.9 1.2 1.2 1.9 2.8 1.9 4.6-.1 3.5-3 6.4-6.6 6.4zm3.5-4.8c-.2-.1-1.1-.6-1.3-.6-.2-.1-.3-.1-.4.1-.1.2-.5.6-.6.8-.1.1-.2.1-.4 0s-.8-.3-1.6-1c-.6-.5-1-1.2-1.1-1.3-.1-.2 0-.3.1-.4l.3-.3s.1-.2.2-.3c.1-.1 0-.2 0-.3s-.4-1.1-.6-1.4c-.2-.4-.3-.3-.4-.3h-.4s-.3 0-.5.2-.7.7-.7 1.6c0 1 .7 1.9.8 2s1.4 2.1 3.3 2.9c.5.2.8.3 1.1.4.5.1.9.1 1.2.1.4-.1 1.1-.5 1.3-.9.2-.5.2-.8.1-.9 0-.2-.2-.3-.4-.4z"/></svg></div>WhatsApp</div>
            </a> */}

            {/* <!-- Sharingbutton Telegram --> */}
            {/* <a className="resp-sharing-button__link" href="https://telegram.me/share/url?text=%C2%BFYa%20llenaste%20el%20Autodiagn%C3%B3stico%20de%20Sostenibilidad%20y%20Congruencia%3F&amp;url=http%3A%2F%2Fhatch.mx%2Fpublic%2Fclientes%2Fautodiagnostico" rel="noreferrer" target="_blank"  aria-label="Telegram">
            <div className="resp-sharing-button resp-sharing-button--telegram resp-sharing-button--medium"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--circle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><circle cx="12" cy="12" r="11.5"/><path d="M2.505 11.053c-.31.118-.505.738-.505.738s.203.62.513.737l3.636 1.355 1.417 4.557a.787.787 0 0 0 1.25.375l2.115-1.72a.29.29 0 0 1 .353-.01L15.1 19.85a.786.786 0 0 0 .746.095.786.786 0 0 0 .487-.573l2.793-13.426a.787.787 0 0 0-1.054-.893l-15.568 6z"/></svg></div>Telegram</div>
            </a> */}
        </div>
    )
}

export default socialNetworks;
