import React, { useRef } from 'react';
import AnswersContext from '../../contexts/answers'
import Questions from './questions'
import { useHistory } from "react-router-dom";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';

function Categories({ categories, token }) {
    const stepRef = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]; // To scroll to the the component when click on next or previous
    const [activeStep, setActiveStep] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [answers] = React.useContext(AnswersContext);
    const Router = useHistory();
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setTimeout(() => {
            stepRef[activeStep+1].current.scrollIntoView({behavior: 'smooth'});
        }, 600)
    };
    const handlePrevious = () => {
        if (activeStep !== 0) {
            setActiveStep(activeStep - 1);
            setTimeout(() => {
                stepRef[activeStep-1].current.scrollIntoView({behavior: 'smooth'});
            }, 600)
        }
    };

    /* Send the answers to the API to be processed */
    const handleFinish = async () => {
        setLoading(true);
        try {
            const result = await axios.post('/diagnostic', { answers }, { headers: { user_token: token } })
            if (result.data.message === "OK") {
                Router.push({ pathname: "/resultados" });
            } else {
                Router.push({ pathname: "/" });
            }
        } catch (error) {
            // unexpected error ocurred, just send him to /
            // console.log(`error`, error);
            Router.push({ pathname: "/" });
        }
    };
    if (categories) {
        return (
            <div className="w-full">
                <Stepper activeStep={activeStep} orientation="vertical">
                    {categories.map((category, index) => (
                        <Step key={index} ref={stepRef[index]}>
                            <StepLabel>{category.category}</StepLabel>
                            <StepContent>
                                <Questions questions={category.questions} />
                                <div className="mb-2 mt-1">
                                    <div>
                                        {/* Check if there are not more questions to change the button text to finish the questionary */}
                                        {(categories.length * categories[0].questions.length === Object.keys(answers).length && activeStep === categories.length - 1) ?
                                            <>
                                                {/* If the submition starts, the loading state is fired */}
                                                {!loading ?
                                                    <>
                                                        <Button
                                                            variant="contained"
                                                            color="default"
                                                            onClick={handlePrevious}
                                                            style={{marginRight: "0.5rem"}}
                                                        >
                                                            Anterior
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleFinish}
                                                            className="mt-1"
                                                            disabled={((Object.keys(answers).length) === 0 ? true : (Object.keys(answers).length) % (3 * (activeStep + 1))) !== 0}
                                                        >
                                                            Terminar
                                                        </Button>
                                                    </>
                                                    :
                                                    <CircularProgress />
                                                }
                                            </>
                                            :
                                            <>
                                                <Button
                                                    variant="contained"
                                                    color="default"
                                                    onClick={handlePrevious}
                                                    style={{marginRight: "0.5rem"}}
                                                >
                                                    Anterior
                                                </Button>
                                                {/* Check if there is more questions to answer to continue to the next category */}
                                                {((Object.keys(answers).length === 0 || (Object.keys(answers).length) % (3 * (activeStep + 1))) !== 0 ) ?
                                                    <>
                                                        {/* If the user gets back to a previous category make the user continue to the next category */}
                                                        {((Object.keys(answers).length / 3) > (activeStep + 1)) ? 
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleNext}
                                                                className="m-10"
                                                            >
                                                                Siguiente
                                                            </Button>
                                                            :
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                className="m-10"
                                                                disabled={((Object.keys(answers).length) === 0 ? true : (Object.keys(answers).length) % (3 * (activeStep + 1))) !== 0}
                                                                >
                                                                Siguiente
                                                            </Button>
                                                        }
                                                        </>
                                                    :
                                                    <>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleNext}
                                                        className="m-10"
                                                        disabled={((Object.keys(answers).length) === 0 || (Object.keys(answers).length) % (3 * (activeStep + 1)) ) !== 0}
                                                    >
                                                        Siguiente
                                                    </Button>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </div>
        )
    } else {
        return null
    }
}

export default Categories;
